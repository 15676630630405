import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from 'primereact/button';
import { ChatListApp } from "./ChatList";
import { CreateChatApp } from "./CreateChat";
import { ChatApp } from "./ChatApp";
import { ChatTabs } from "./ChatTabs";

export function ChatMain({ close }) {
    const [visibleListChats, setVisibleChats] = useState(true);
    const [visibleCreateChat, setVisibleCreateChat] = useState(false);
    const [visibleChatApp, setVisibleChatApp] = useState(false);
    const [chatSelectedID, setChatSelectedID] = useState(null);
    const [enabledToCreate, setEnabledToCreate] = useState(false);
    const [currentTab, setCurrentTab] = useState("Activity");
    const dataLocation = useLocation();
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const emailLink = urlParams.get('emailLink');
        if(emailLink) setCurrentTab("Comments")
    }, []);
    useEffect(() => {
        const dataLocationArray = dataLocation.pathname.split('/');
        if (dataLocationArray[3] === "details") {
            setEnabledToCreate(true);
        } else if (enabledToCreate) {
            setEnabledToCreate(false);
        }
    });

    const setChatSelected = (chatID) => {
        setChatSelectedID(chatID);
        setVisibleChats(false);
        setVisibleCreateChat(false);
        setVisibleChatApp(true);
    };

    return (
        <div className="container shadow-2xl card h-[100vh] right-0 bg-white" style={{width:384}}>
            <div className="flex w-full justify-end mt-5 pr-4 gap-4">
                <div />
                <div>{enabledToCreate && <button type="button" onClick={() => {
                    setVisibleChats(false);
                    setVisibleChatApp(false);
                    setVisibleCreateChat(true);
                }} className="p-sidebar-icon p-link mr-0 z-30 fixed">
                    {!visibleChatApp && currentTab === "Comments" ? <span className="pi pi-plus" /> : null}
                </button>
                }</div>
            </div>
            <br />
            <div className="fixed h-full">
                <div className="px-3">
                    <ChatTabs handleChange={(tab) => setCurrentTab(tab)} />
                </div>
                {
                    currentTab === "Activity" ? (
                        null
                    ) : (
                        <div className="flex max-w-sm h-full min-h-3/4">
                            {visibleListChats && <ChatListApp close={setVisibleChats} setChatSelected={setChatSelected} pathname={dataLocation.pathname} />}
                            {visibleCreateChat && <CreateChatApp close={setVisibleCreateChat} setChatSelected={setChatSelected} dataLocation={dataLocation.pathname} />}
                            {visibleChatApp && <ChatApp close={visibleChatApp} setVisible={setVisibleChatApp} chatSelectedID={chatSelectedID} dataLocation={dataLocation.pathname}/>}
                        </div>
                    )
                }
            </div>
        </div >
    )
}
