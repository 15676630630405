import React, { useState } from 'react';
import { ChatList, ChatListItem, MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';

export const ChatInterface = () => {
    const [messages, setMessages] = useState([
        { position: 'left', type: 'text', text: 'Quería hablar contigo sobre el accidente que ocurrió en la obra la semana pasada. ¿Puedes decirme qué sucedió?' },
        { position: 'right', type: 'text', text: 'Según nuestra investigación, el accidente ocurrió porque uno de los trabajadores no seguía las medidas de seguridad establecidas y se resbaló en una superficie mojada.' },
        { position: 'left', type: 'text', text: '¿Qué medidas se tomarán para prevenir que esto vuelva a suceder?' },
        { position: 'right', type: 'text', text: 'Ya hemos reforzado las medidas de seguridad y hemos implementado una capacitación adicional para los trabajadores. También estamos revisando nuestro protocolo de seguridad para asegurarnos de que esté actualizado y completo.' },
        { position: 'left', type: 'text', text: 'Me alegra saber que están tomando medidas para evitar futuros accidentes. Gracias por tu tiempo y tu trabajo en la obra.' },
    ]);
    const [newMessage, setNewMessage] = useState('');

    const handleNewMessageChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = () => {
        const message = { position: 'right', type: 'text', text: newMessage };
        setMessages([...messages, message]);
        setNewMessage('');
    };

    return (
        <div>
            {messages.map((message, index) => (
                <MessageBox
                    key={index}
                    position={message.position}
                    type={message.type}
                    text={message.text}
                />
            ))}
            <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                <input
                    style={{ flex: 1, marginRight: '10px' }}
                    type="text"
                    placeholder="Escriba un mensaje..."
                    value={newMessage}
                    onChange={handleNewMessageChange}
                />
                { // eslint-disable-next-line react/button-has-type
                    <button onClick={handleSendMessage}>Enviar</button>
                }
            </div>
        </div>
    );
}