import React, { useState, useEffect } from "react";
import { DataTable } from 'primereact/datatable';
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { ChatList } from "react-chat-elements";
import 'react-chat-elements/dist/main.css';
import { useChats } from "../../api/Chat";
import { useStaff } from "../../api/Staff";
import { Spinner } from "../../common";


export function ChatListApp({ setChatSelected, pathname }) {
    const chats = useChats(pathname);

    useEffect(() => {
        if(chats?.data?.length){
            setChatSelected(chats?.data[0].id)
        }
    }, [chats?.data])


    if (chats.isLoading) {
      return <div style={{width:384}}><Spinner /></div>
    }

    return (
      <div className="w-full">
        <p className="w-full px-3 py-4 text-3xl">Comments</p>
        <br />
        {chats?.data?.length ? (
         null
        ) : (
          <p className="px-3">To start comments, click on the + at the top of the page</p>
        )}
      </div>
    );
}
