import { AutoComplete } from 'primereact/autocomplete';
import { XCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState } from 'react';
import { Dropdown } from "../../Dropdown";

let OptionsstreetVal = []

export function Address({
  streetId,
  streetId2,
  cityId,
  postalId,
  fullAddress,
  streetVal,
  street2Val,
  cityVal,
  postalVal,
  handleChange,
  handleBlur,
  setFieldValue
}) {

  const [places, setPlaces] = useState([]);
  const [addressLookup, setAddressLookup] = useState("");

  useEffect(() => {
    if (streetVal)
      setAddressLookup(streetVal)
  }, [streetVal])

  const captureName = (event) => {
    const nomainUrl = "https://nominatim.openstreetmap.org/search?";
    const params = {
      q: event.query,
      format: 'json',
      addressdetails: 'addressdetails',
      limit: 10
    };
    const newParams = new URLSearchParams(params).toString();
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    fetch(`${nomainUrl}${newParams}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newOptions = JSON.parse(result);

        OptionsstreetVal = newOptions.map((row, index) => {
          const { address } = row;
          const city = address?.city;
          const postcode = address?.postcode;
          const display_name = row?.display_name
          const longitude = row.lon;
          const latitude = row.lat;
          const suburb = address?.suburb

          return {
            city, postcode, longitude, latitude, suburb,
            value: index,
            label: display_name,
            fullAddress: display_name,
            partial: event.query
          }
        })
        console.log(OptionsstreetVal)
        setPlaces(OptionsstreetVal);
      })
      .catch((error) => console.log("err", error))
  }

  return (
    <div className="px-4 py-4 mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-6 md:gap-6">
        <div className="col-span-6">
          <label
            htmlFor={streetId}
            className="block text-sm font-medium text-gray-700"
          >
            Street address
          </label>
          <br />
          <AutoComplete
            value={addressLookup}
            suggestions={places}
            completeMethod={captureName}
            field="fullAddress"
            dropdown
            forceSelection
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            // itemTemplate={itemTemplate}
            onChange={(e) => {
              setAddressLookup(e.value)
            }}
            onSelect={(row) => {
              setFieldValue("street", row?.value?.partial);
              setFieldValue("street2", row?.value?.suburb || "");
              setFieldValue("city", row?.value?.city || "");
              setFieldValue("postal", row?.value?.postcode || "");
              setFieldValue("longitude", row?.value?.longitude || "");
              setFieldValue("latitude", row?.value?.latitude || "");
              setFieldValue('fullAddress', row?.value?.fullAddress);
            }}
            aria-label="Place"
            dropdownAriaLabel="Select Place" />
        </div>
        <div className="col-span-3">
          <label
            htmlFor={streetId2}
            className="block text-sm font-medium text-gray-700"
          >
            Street 2
          </label>
          <input
            type="text"
            name={streetId2}
            id={streetId2}
            autoComplete="street-address"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={street2Val}
          />
        </div>
        <div className="col-span-3 sm:col-span-3 lg:col-span-3 flex items-center">
          <XCircleIcon className="mt-7 w-6 h-6 text-indigo-500" />
          <button
            type="button"
            className="mt-7 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
            onClick={() => {
              setFieldValue("street", "");
              setFieldValue("street2", "");
              setFieldValue("city", "");
              setFieldValue("postal", "");
              setFieldValue("longitude", "");
              setFieldValue("latitude", "");
              setFieldValue('fullAddress', "");
              setAddressLookup("")
            }}
          >
            Clear Address
          </button>
        </div>

        <div className="col-span-3 sm:col-span-3 lg:col-span-3">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <input
            type="text"
            name={cityId}
            id={cityId}
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={cityVal}
          />
        </div>
        <div className="col-span-3 sm:col-span-3 lg:col-span-3">
          <label
            htmlFor="postal-code"
            className="block text-sm font-medium text-gray-700"
          >
            Postal Code
          </label>
          <input
            type="text"
            name={postalId}
            id={postalId}
            autoComplete="postal-code"
            className="block w-full mt-1 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            onChange={handleChange}
            onBlur={handleBlur}
            value={postalVal}
          />
        </div>
      </div>
    </div>
  );
}