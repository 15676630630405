import { useMutation, useQueryClient } from "react-query";
import axios from 'axios'
import supabase from "../supabase";
import { useNotificationStore } from "../../store/notifications";

async function createChat(payload) {
    const { data, error } = await supabase.from("Chats").insert(payload);

    if (error) {
        throw new Error(error.messge);
    }

    return data;
}

export function useCreateChat() {
    const { addNotification } = useNotificationStore();

    const queryClient = useQueryClient();

    return useMutation((payload) => createChat(payload), {
        onSuccess: () => {
            queryClient.refetchQueries("chats");
            addNotification({
                isSuccess: true,
                heading: "Success!",
                content: `Successfully created Chat.`,
            });
        },
        onError: (err) => {
            addNotification({
                isSuccess: false,
                heading: "Failed creating Chat",
                content: err?.message,
            });
        },
        mutationFn: createChat,
    });
}
