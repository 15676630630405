import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchChatMessages(id) {
    const user = supabase.auth.user();
    const { data, error } = await supabase
        .from("Chat_Messages")
        .select(`*`)
        .eq("chat_id", id)
        .order("sended_at")

    if (error) {
        throw new Error(error.message);
    }
    // const chatsFilters = data.filter(e =>
    //     e.members.find(r => r === user.email) !== undefined ? true : false
    // );
    // ,

    const chats = data.map(row => ({
        position: row.sender === user.email ? 'right' : 'left',
        type: 'text',
        text: row.message,
        date: new Date(),
        sender:row.sender,
        sender_name:row.sender_name,
        id:row.id
    }));

    return chats;
}

export function useFetchChatMessages(chatId) {
    return useQuery({
        queryKey: ["chatMessages", chatId],
        queryFn: () => fetchChatMessages(chatId),
    });
}
