import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import 'react-chat-elements/dist/main.css';
import { useActiveStaffStaff } from "../../api/Staff";
import { Spinner } from "../../common/Spinner";
import supabase from "../../api/supabase";
import { useCreateChat, useCreateMessage } from "../../api/Chat";

export function CreateChatApp({ setChatSelected, dataLocation }) {
    const user = supabase.auth.user();
    const [staffSelected, setStaffSelected] = useState([]);
    const staffData = useActiveStaffStaff();
    const createChatMutation = useCreateChat();
    const CreateMessageMutation = useCreateMessage();

    const renderStaffList = () => {
        if (staffData?.data && staffData?.data?.length > 0) {
            return staffData.data.map((staff) => ({
                label: staff.staff_name,
                value: staff.email,
            }));
        }
        return [];
    };

    const handleOnSubmit = async () => {
        const payloadChat = {
            topic: dataLocation,
            participants: [user.email],
            createdBy: user.email
        };
        const chatCreated = await createChatMutation.mutateAsync(payloadChat);

        const payloadMessage = {
            chat_id: chatCreated[0].id,
            sender: user.email,
            message: `This comment was created by: ${user.user_metadata.name}. this is the start of the conversation`
        };

        await CreateMessageMutation.mutateAsync(payloadMessage);
        setChatSelected(chatCreated[0].id);
    };

    if (staffData.isLoading) {
        return <div style={{width:384}}><Spinner /></div>
    }

    return (
        <div className="w-full h-full container p-3 justify-center">
            <h2>Create New Comment</h2>
            <br />
            <p className="text-sm">You are going to create a new conversation based on this detail page.
            </p>
            <br />
            <div className="flex items-center">
                <Button className="w-full" label="Create" icon="pi pi-check" onClick={handleOnSubmit} />
            </div>
        </ div>
    )
}