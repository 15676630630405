import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";
import { useNotificationStore } from "../../store/notifications";

async function createMessage(message) {
    const { data, error } = await supabase.from("Chat_Messages").insert(message);

    if (error) {
        throw new Error(error.messge);
    }

    return data;
}

export function useCreateMessage() {
    const { addNotification } = useNotificationStore();

    const queryClient = useQueryClient();

    return useMutation((message) => createMessage(message), {
        onSuccess: () => {
            queryClient.refetchQueries("chatMessages");

        },
        onError: (err) => {
            addNotification({
                isSuccess: false,
                heading: "Failed creating job",
                content: err?.message,
            });
        },
        mutationFn: createMessage,
    });
}
