import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Button } from "primereact/button";
import { MessageBox } from "react-chat-elements";
import { ChatContainer, MessageList, Message, MessageInput } from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { InputTextarea } from 'primereact/inputtextarea';
import { VirtualScroller } from "primereact/virtualscroller";
import { Dialog } from 'primereact/dialog';
import 'react-chat-elements/dist/main.css';
import { useFetchChat, useFetchChatMessages, useCreateMessage, useUpdateChat } from "../../api/Chat";
import { Spinner } from "../../common/Spinner";
import supabase from "../../api/supabase";
import { Input, TextArea } from "../../common";
import { useStaff } from "../../api/Staff";
import sendEmail from "../../api/Chat/sendEmail";




export function ChatApp({ visible, setVisible, chatSelectedID,dataLocation }) {
    const user = supabase.auth.user();
    const messageListReferance = React.createRef();
    const [list, setList] = useState([]);
    const chat = useFetchChat(chatSelectedID);
    const staff = useStaff();
    const messages = useFetchChatMessages(chatSelectedID);
    const sendMessageMutation = useCreateMessage();
    const [updated, setUpdated] = useState(false);
    const [participants, setParticipants] = useState([])
    const [modal, setModal] = useState(false)
    const [text, setText] = useState("")
    const [matchingUsers, setMatchingUsers] = useState()
    const [emails, setEmails] = useState([])
    const virtualScrollerRef = useRef(null);
    const updateParticipants = useUpdateChat();
    useEffect(() => {
        setTimeout(() => {
            messages.refetch();
            setUpdated(!updated);
        }, 5000);
    }, [updated])

    useEffect(() => {        
        if(chat.data){
            setParticipants(chat?.data?.participants.map(participant => (staff?.data?.find(staffRow => staffRow.email === participant)?.staff_name)))
        }
    }, [chat.data,staff.data])
    useEffect(() => {
        const users =staff?.data
        const words = text.split(' ');
        const mentions = words.filter((word) => word.startsWith('@'));
        const matching = mentions.length === 1
        ? users?.filter(user => user.staff_name.toLowerCase().startsWith(mentions[0].toLowerCase().substring(1)))
        : [];
        setMatchingUsers(matching)
        
    }, [text])
    


    function formatBody(topic, topicID) {
        return `<div>
        <p> You have been mentioned in the comments for ${topic} #${topicID}. </p>
        <p>To access the details please click <a href="https://nzsg.scaffm8.nz/${dataLocation}?emailLink=true" target="_blank">here</a></p>
        <p>Regards,</p>
        <p>NZ SG Group</p>
        </div>`
      }



    const handleSendMessage = async (dataForMessage) => {
        const message = { chat_id: chatSelectedID, sender: user.email, sender_name: user.user_metadata.name, message: dataForMessage };
        sendMessageMutation.mutateAsync(message)
        if(!chat?.data?.participants.includes(user.email) && !participants.includes(user.user_metadata.name) ){
            const chatPayload = {
                ...chat.data,
                participants:[...chat.data.participants,user.email]
            };
            setParticipants([...participants,user.user_metadata.name])
            updateParticipants.mutateAsync({participants:chatPayload, chatID:chatSelectedID})
        }
        
        messages.refetch();
        setText("")
        const dataLocationArray=dataLocation.split('/')
        const title = `You have been mentioned in the comments for ${dataLocationArray[1]} #${dataLocationArray[2]}`;
        const body = formatBody(dataLocationArray[1],dataLocationArray[2])


        try {
            await sendEmail({ title, body, emailTo: emails })
        } catch (err) {
            console.log("ERROR", err);
        }
    };


    if (chat.isLoading || messages.isLoading) {
        return <div style={{width:384}}><Spinner /></div>
    }
    return (
        <div className="w-full p-1">
            <h2 className="px-3">Comments</h2>
            <Dialog header="Participants" visible={modal} onHide={() => setModal(false)}
                style={{ width: '20vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                {participants.map((staff) =>
                    <p>{staff}</p>
                )}
            </Dialog>
                <button type="button" className="flex gap-x-1 ml-3" onClick={() => setModal(true)}>
                <p>
                    Participants:
                </p>
                {participants.map((participant, index) => {
                    if (index < 2 ) {
                        return <p>{participant?.split(" ")[0]}{index < participants.length-1?",":null}</p>
                    }
                    if (index === 2) {
                        return <p>{participant?.split(" ")[0]}... </p>
                    }
                    return null
                }
                )}
                </button>
            
            <br />
            <div className="container card h-full">

            {matchingUsers?.length?<div className="absolute z-10 w-full flex flex-col items-start py-2 rounded-lg mx-3" style={{background:"#c6e3fa",bottom:100, width: "calc(100% - 32px)", overflow:"auto", maxHeight:150}}>
                {matchingUsers?.map((user,index) => (
                    <button type="button" className="hover:bg-white text-left w-full px-3 py-2" onClick={() => {
                        const checkMention=text.split(' ').filter((word) => word.startsWith('@'))
                        if (text.includes(checkMention[0])) {
                            setText(text.replace(checkMention[0], matchingUsers[index].staff_name))
                            setEmails([...emails,matchingUsers[index].email])
                            const input = document.querySelector('.cs-message-input__content-editor');
                            input.focus();
                        }
                        
                    }} key={user.staff_name}>{user.staff_name}</button>
                ))}
            </div>:null}
            <div className="container card h-full">
                <ChatContainer className="min-w-full h-full">
                    <MessageList className="h-full" autoScrollToBottomOnMount scrollBehavior="auto" autoScrollToBottom >
                        <MessageList.Content style={{marginBottom:180}}>
                            {messages.data.map(messageRow => (
                                <Message
                                    key={messageRow.id}
                                    model={{
                                        message: messageRow.text,
                                        sentTime: messageRow.date,
                                        sender: messageRow.sender,
                                        direction: messageRow.sender === user.email ? "outgoing" : "incoming"
                                    }}
                                >
                                    <Message.Header>
                                        {messageRow.sender_name}
                                    </Message.Header>
                                </Message>
                            ))}
                        </MessageList.Content>
                    </MessageList>

                    <MessageInput
                        value={text}
                        className="absolute bottom-0"
                        placeholder="Type a message..."
                        attachButton={() => <></>}
                        onSend={handleSendMessage}
                        onChange={(value) => setText(value)}
                        autoFocus
                    />
                </ChatContainer>
            </div>
        </div>
    </div>
    )
}