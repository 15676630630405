import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllChats(topic) {
    const user = supabase.auth.user();
    const { data, error } = await supabase
        .from("Chats")
        .select("*")
        .eq("topic", topic);
    if (error) {
        throw new Error(error.message);
    }
    return data
}
export function useChats(topic) {
    return useQuery({
        queryKey: ["chat", topic],
        queryFn: () => fetchAllChats(topic),
    });
}
