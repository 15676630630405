import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchChat(id) {
    const { data, error } = await supabase
        .from("Chats")
        .select(`*`)
        .eq("id", id);
    if (error) {
        throw new Error(error.message);
    }
    return data[0];
}

export function useFetchChat(chatId) {
    return useQuery({
        queryKey: ["chat", chatId],
        queryFn: () => fetchChat(chatId),
    });
}
