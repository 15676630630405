import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";
import { updateTask } from "../Jobs";

export async function updateHire({ hire, hireId }) {

  if (!hire.weekly_hire_rate) {
    throw new Error("Weekly hire rate is required");
  }

  const { data, error } = await supabase
    .from("weekly_hire_invoices")
    .update(hire)
    .match({ id: hireId });

  if (error) {
    throw new Error(error.message);
  }

  const newPayloadTask = {
    "percentage_complete": data[0]?.completed >= 70 ? 70 : data[0]?.completed,
    "percentage_erect": data[0]?.completed,
    "percentage_dismantle": 0
  };
  updateTask({ payload: newPayloadTask, taskId: data[0]?.task_id });

  return data;
}

export const useUpdateHire = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((hire) => updateHire(hire), {
    onSuccess: (data) => {
      queryClient.refetchQueries("weeklyHires");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated hire invoice.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating hire invoice",
        content: err?.message,
      });
    },
    mutationFn: updateHire,
  });
};
