import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

export async function updateChat({ participants, chatID }) {
  const { data, error } = await supabase.from("Chats").update(participants).match({ id: chatID });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateChat = () => {
  const queryClient = useQueryClient();

  return useMutation((participants) => updateChat(participants), {
    onSuccess: (data) => {
      queryClient.refetchQueries("Chats");
    },

    onError: (err) => {
        console.log(err)
    },
    mutationFn: updateChat,
  });
};